import { Link } from 'gatsby'
import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'

// http://www.dktech.cz/?page_id=207

const EventGeocaching: FC = () => {
  return (
    <PageTemplate>
      <div className="topTitle">
        <h1><Link to="/event_geocaching">Geocaching event</Link></h1>
      </div>
      <p><strong>GEOCACHING EVENT</strong></p>
      <p>Jak již vyplývá z názvu byl tento event motivován celosvětovou hrou Geocaching. GM na jistém místě ve hře schová poklad. Hráčům napíše souřadnice tohoto místa ve tvaru x, y, z. Hráči mají za úkol toto místo vyhledat a poklad vyzvednout. Nejrychlejší hráč si může poklad ponechat. Svoji aktuální polohu si může hráč zjistit zadáním příkazu /loc .</p>
      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default EventGeocaching

export { Head } from '../components/defaultHtmlHead'